import { TextField } from "final-form-material-ui";
import { FunctionComponent, useEffect, useState } from "react";
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { validate } from "../../utils/validate";
import { schema } from "./schema";
import { format } from "../../utils/form";
import { FormButton, FormContainer, FormContent, FormInfoContainer, InputContainer, MsgCaptchaRequired } from "./styles";
import LinkButton from "../Buttons/LinkButton";
import { useTheme } from "styled-components";
import SmileIcon from "../../images/icone-sorriso.png";
import CellphoneIcon from "../../images/icone-telefone.png";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { TipoContato } from "../../services/EnvioEmail/types";
import { EnviarEmail } from "../../services/EnvioEmail/envioEmail";
import GoogleReCaptcha from "../ReCaptcha/ReCaptcha";
import secureLocalStorage from "react-secure-storage";
import { toggleElementById } from "../../utils/toggleElementById";
import { useCaptcha } from "../../contexts/CaptchaProvider";

interface FormProps {
  formTitle: string;
  formText: string;
  contactType: TipoContato;
}

const ContactForm: FunctionComponent<FormProps> = ({ formTitle, formText, contactType }) => {
  const CPF_MASK = 'XXX.XXX.XXX-XX';
  const CNPJ_MASK = 'XX.XXX.XXX/XXXX-XX';
  const PHONE_MASK = format('(99) 99999-9999');
  const focusOnErrors = createDecorator();
  const theme = useTheme();
  const [submitting, setSubmitting] = useState(false);
  const { resetCaptcha } = useCaptcha();

  useEffect(
    () => {
      secureLocalStorage.removeItem('grecaptcha')
    }, []
  )

  const handleFormSubmit = async (dataForm: any) => {
    const grecaptcha = secureLocalStorage.getItem('grecaptcha') as string
    toggleElementById('wt-grecaptcha', !grecaptcha)

    if (grecaptcha) {
      setSubmitting(true);
      try {
        const { data } = await EnviarEmail({ TipoContato: contactType, ...dataForm, Captcha: grecaptcha });
        toast.success("Mensagem enviada com sucesso!");
      }
      catch (error) {
        toast.error("Tivemos um problema ao enviar a mensagem. Tente novamente mais tarde!");
      }
      finally {
        setSubmitting(false);
        resetCaptcha();
      }
    }
  }

  const [formData, setFormData] = useState({
    Cpf_Cnpj: '',
    Email: '',
    InstituicaoFinanceira: '',
    Telefone: '',
    MotivoContato: contactType === TipoContato.Ouvidoria ? '' : '-',
    Mensagem: '',
    Aceite: false
  });

  return (
    <Form
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      subscription={{ submitting: true, values: true }}
      onSubmit={handleFormSubmit}
      decorators={[focusOnErrors]}
      initialValues={formData}
      validate={validate(schema)}
      keepDirtyOnReinitialize
      render={(props: any) => {
        const isCnpj = props.values?.Cpf_Cnpj?.toString().length > 11;
        const documentoMask = format(isCnpj ? CNPJ_MASK : CPF_MASK);
        return (
          <FormContainer>
            <FormInfoContainer>
              <h3>{formTitle}</h3>
              <p>{formText}</p>
              <LinkButton
                linkTo={"https://portal.bp.com.vc/"}
                bgColor={theme.colors.secondary}
                textColor={theme.colors.white}
                openInNewTab={true}
                title={"Negocie você mesmo"}
                hoverColor={theme.colors.secondaryHover}
                leftIcon={SmileIcon}
                width={195}
              />
            </FormInfoContainer>
            <FormContent>
              <form onSubmit={props.handleSubmit} noValidate>
                <InputContainer>
                  <Field
                    name="Email"
                    type="text"
                    component={TextField}
                    label="Email"
                    fullWidth
                    variant="outlined"
                  />
                </InputContainer>
                <InputContainer>
                  <Field
                    {...documentoMask}
                    name="Cpf_Cnpj"
                    type="text"
                    component={TextField}
                    label="CPF ou CNPJ"
                    fullWidth
                    variant="outlined"
                  />
                </InputContainer>
                <InputContainer>
                  <Field
                    name="InstituicaoFinanceira"
                    type="text"
                    component={TextField}
                    label="Instituição Financeira"
                    fullWidth
                    variant="outlined"
                  />
                </InputContainer>
                <InputContainer>
                  <Field
                    name="Telefone"
                    maxLength="14"
                    type="text"
                    placeholder="(XX) 9XXXX-XXXX"
                    component={TextField}
                    label="Telefone"
                    fullWidth
                    variant="outlined"
                    {...PHONE_MASK}
                  />
                </InputContainer>
                {contactType === TipoContato.Ouvidoria && (
                  <InputContainer>
                    <Field
                      name="MotivoContato"
                      maxLength="14"
                      type="text"
                      component={TextField}
                      label="Motivo do Contato"
                      fullWidth
                      variant="outlined"
                    />
                  </InputContainer>
                )}
                <InputContainer>
                  <Field
                    className="text-area"
                    name="Mensagem"
                    type="text"
                    component={TextField}
                    label={contactType === TipoContato.Ouvidoria ? "Descreva o motivo do seu contato" : "Mensagem"}
                    fullWidth
                    multiline
                    variant="outlined"
                  />
                </InputContainer>
                {contactType === TipoContato.FaleConosco && (
                  <InputContainer>
                    <Field
                      name="Aceite"
                      component="input"
                      type="checkbox"
                      id="Aceite"
                    />
                    <label htmlFor="Aceite">Aceito receber conteúdo via SMS e RCS</label>
                  </InputContainer>
                )}

                <GoogleReCaptcha />
                <MsgCaptchaRequired id="wt-grecaptcha">Captcha obrigatório.</MsgCaptchaRequired>

                <FormButton
                  disabled={submitting}
                  type="submit"
                >
                  {
                    submitting ? <CircularProgress style={{ 'color': `${theme.colors.primary.normal}` }} size={23} /> : 'Enviar'
                  }
                </FormButton>
              </form>
            </FormContent>
          </FormContainer>
        )
      }}
    />

  );
}

export default ContactForm;