import { useEffect, useState } from "react";
import GoogleReCaptcha from "../../components/ReCaptcha/ReCaptcha";
import TopFormBanner from "../../components/TopBanner/TopFormBanner";
import { theme } from "../../config/Theme";
import SapBanner from "../../images/capa-validar-boleto-min.jpg";
import { FormValidacaoBoleto, InputGroup, InputValidacaoBoleto, LegendaInput, ModalBox, ModalBoxContent, ModalBoxTexts, ModalButton, ModalValidacaoBoleto, ParagraphDescription, SectionValidacaoBoleto, SubmitButton, WarningText } from "./styles";
import { ArrowRight, X } from "phosphor-react";
import { toggleElementById } from "../../utils/toggleElementById";
import { CircularProgress } from "@mui/material";
import { IValidarBoletoIn, IValidarBoletoOut, validarBoleto } from "../../services/ValidarBoleto/validarBoletoUtils";
import { toggleModalById } from "../../utils/scrollLock";
import boletoAutentico from "../../images/boleto-autentico.svg"
import boletoNaoAutentico from "../../images/boleto-nao-autentico.svg"
import secureLocalStorage from "react-secure-storage";
import { useCaptcha } from "../../contexts/CaptchaProvider";

const validarCpf = (cpf: string): boolean => {
  if (/^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.charAt(i - 1)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(9))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.charAt(i - 1)) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(10))) return false;

  return true;
};

const validarCnpj = (cnpj: string): boolean => {
  if (/^(\d)\1{13}$/.test(cnpj)) return false;

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0))) return false;

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1))) return false;

  return true;
};

const formatLinhaDigitavel = (linhaDigitavel: string): string => {
  const digits = linhaDigitavel.replace(/\D/g, '');

  return digits
    .replace(/^(\d{5})(\d)/, '$1.$2')
    .replace(/^(\d{5}\.\d{5})(\d)/, '$1 $2')
    .replace(/^(\d{5}\.\d{5} \d{5})(\d)/, '$1.$2')
    .replace(/^(\d{5}\.\d{5} \d{5}\.\d{6})(\d)/, '$1 $2')
    .replace(/^(\d{5}\.\d{5} \d{5}\.\d{6} \d{5})(\d)/, '$1.$2')
    .replace(/^(\d{5}\.\d{5} \d{5}\.\d{6} \d{5}\.\d{6})(\d)/, '$1 $2')
    .replace(/^(\d{5}\.\d{5} \d{5}\.\d{6} \d{5}\.\d{6} \d)(\d)/, '$1 $2');
};

const formatDocumento = (documento: string): string => {
  const digits = documento.replace(/\D/g, '');

  if (digits.length <= 11) {
    return digits
      .replace(/^(\d{3})(\d)/, '$1.$2')
      .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
  } else {
    return digits
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
      .replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');
  }
};

export default function ValidarBoleto() {
  const [linhaDigitavel, setLinhaDigitavel] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [linhaDigitavelWarning, setLinhaDigitavelWarning] = useState("")
  const [documentoWarning, setDocumentoWarning] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [retornoValidacaoBoleto, setRetornoValidacaoBoleto] = useState<IValidarBoletoOut | undefined>(undefined)
  const [imagemModal, setImagemModal] = useState(boletoNaoAutentico)
  const { resetCaptcha } = useCaptcha()

  useEffect(
    () => {
      toggleModalById("modal-validacao-boleto", document.body, retornoValidacaoBoleto != undefined)
    }, [retornoValidacaoBoleto]
  )

  useEffect(
    () => {
      secureLocalStorage.removeItem("grecaptcha")
    }, []
  )

  const validarDocumento = (): boolean => {
    const digits = cpfCnpj.replace(/\D/g, '');
    let documentState = false

    if (digits.length === 0) {
      setDocumentoWarning("Documento obrigatório *")
    }
    else if (digits.length > 0 && digits.length != 11 && digits.length != 14) {
      setDocumentoWarning("Documento incompleto *")
    }
    else if (digits.length === 11) {
      documentState = validarCpf(digits);

      if (!documentState) {
        setDocumentoWarning("Documento inválido *")
      }
    }
    else if (digits.length === 14) {
      documentState = validarCnpj(digits);

      if (!documentState) {
        setDocumentoWarning("Documento inválido *")
      }
    }

    toggleElementById('wt-documento', !documentState)

    return documentState;
  };

  const validarLinhaDigitavel = (): boolean => {
    const digits = linhaDigitavel.replace(/\D/g, '');
    const linhaDigitavelState = digits.length === 47;

    if (digits.length === 0) {
      setLinhaDigitavelWarning("Linha digitável obrigatória *")
    }
    else if (digits.length > 0 && digits.length != 47) {
      setLinhaDigitavelWarning("Linha digitável incompleta *")
    }

    toggleElementById('wt-linha-digitavel', !linhaDigitavelState)
    return linhaDigitavelState
  }

  const validarCaptcha = (): boolean => {
    const grecaptcha = secureLocalStorage.getItem("grecaptcha")
    const grecaptchaState = !!grecaptcha

    toggleElementById('wt-grecaptcha', !grecaptchaState)
    console.log(grecaptchaState)

    return grecaptchaState;
  }

  const validarFormulario = (): boolean => {
    const documentoState: boolean = validarDocumento()
    const linhaDigitavelState: boolean = validarLinhaDigitavel()
    const grecaptchaState = validarCaptcha()

    return documentoState && linhaDigitavelState && grecaptchaState
  }

  return (
    <>
      <TopFormBanner
        shadowColor={theme.colors.secondary}
        bannerImg={SapBanner}
        bgColor={theme.colors.grey.light}
      >
        <SectionValidacaoBoleto>
          <h1>Validação de boleto</h1>
          <ParagraphDescription>Para validar a autenticidade do boleto que você recebeu basta digitar abaixo a linha digitável (código) do seu boleto e o seu documento de identidade (CPF ou CNPJ):</ParagraphDescription>

          <FormValidacaoBoleto>

            <InputGroup>
              <LegendaInput>Digite a linha digitável do boleto (código)</LegendaInput>
              <WarningText id="wt-linha-digitavel">{linhaDigitavelWarning}</WarningText>
              <InputValidacaoBoleto
                id="input-linha-digitavel"
                type="text"
                value={linhaDigitavel}
                onChange={
                  (e) => {
                    const formattedValue = formatLinhaDigitavel(e.target.value);
                    setLinhaDigitavel(formattedValue);
                  }
                }
                maxLength={54}
                onKeyUp={validarFormulario}
                inputMode="numeric"
                placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
              />
            </InputGroup>

            <InputGroup>
              <LegendaInput>Digite seu documento (CPF ou CNPJ)</LegendaInput>
              <WarningText id="wt-documento">{documentoWarning}</WarningText>
              <InputValidacaoBoleto
                type="text"
                maxLength={18}
                value={cpfCnpj}
                onChange={
                  (e) => {
                    const formattedValue = formatDocumento(e.target.value)
                    setCpfCnpj(formattedValue)
                  }
                }
                onKeyUp={validarFormulario}
                placeholder="CPF/CNPJ"
              />
            </InputGroup>

            <InputGroup>
              <WarningText id="wt-grecaptcha">Captcha obrigatório *</WarningText>
              <GoogleReCaptcha />
            </InputGroup>

            <SubmitButton
              type="button"
              disabled={submitting}
              onClick={
                async () => {

                  if (validarFormulario()) {
                    setSubmitting(!submitting)
                    const captcha = secureLocalStorage.getItem('grecaptcha') as string

                    const _validarBoletoIn: IValidarBoletoIn = {
                      linhaDigitavel: linhaDigitavel,
                      cpf: cpfCnpj.replace(/\D/g, ''),
                      captcha: captcha,
                    }
                    await validarBoleto(_validarBoletoIn)
                      .then(
                        (result) => {
                          setRetornoValidacaoBoleto(result)
                          setImagemModal(result.isValid ? boletoAutentico : boletoNaoAutentico)
                          setSubmitting(false)
                        }
                      )
                      .catch(
                        () => {
                          setSubmitting(false)
                        }
                      )
                  }
                }
              }
            >
              {
                submitting ?
                  (
                    <CircularProgress
                      style={{ 'color': `${theme.colors.white}` }} size={23}
                    />
                  )
                  :
                  (
                    <>
                      <p>Validar boleto</p>
                      <ArrowRight size={16} />
                    </>
                  )
              }
            </SubmitButton>

          </FormValidacaoBoleto>

        </SectionValidacaoBoleto>
      </TopFormBanner>

      <ModalValidacaoBoleto open={retornoValidacaoBoleto != undefined} id="modal-validacao-boleto">
        <ModalBox>

          <ModalBoxContent>

            <img src={imagemModal} />

            <ModalBoxTexts>
              <h4>
                {
                  retornoValidacaoBoleto?.isValid ?
                    (
                      "O boleto é autêntico"
                    )
                    :
                    (
                      "O boleto não é autêntico"
                    )
                }
              </h4>

              <p>
                {
                  retornoValidacaoBoleto?.isValid ?
                    (
                      "Verificamos em nosso sistema, e o boleto foi autenticado com sucesso!"
                    )
                    :
                    (
                      "O boleto não existe ou não pertence à Bellinati Perez!"
                    )
                }
              </p>
            </ModalBoxTexts>

            <ModalButton
              onClick={
                () => {
                  toggleModalById("modal-validacao-boleto", document.body, false)
                  resetCaptcha()
                }
              }
            >
              Ok
            </ModalButton>

          </ModalBoxContent>
        </ModalBox>
      </ModalValidacaoBoleto>
    </>
  )
}