import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import secureLocalStorage from 'react-secure-storage';
import { toggleElementById } from '../../utils/toggleElementById';

export default function GoogleReCaptcha() {
	const recaptchaRef = React.createRef<ReCAPTCHA>();

	return (
		<ReCAPTCHA
			ref={recaptchaRef}
			sitekey={`${process.env.REACT_APP_RECAPTCHA_SITEKEY}`}
			hl='pt-br'
			onChange={
				() => {
					if (recaptchaRef?.current?.getValue()) {
						secureLocalStorage.setItem('grecaptcha', `${recaptchaRef.current.getValue()}`)
						toggleElementById('wt-grecaptcha', false)
					}
				}
			}
			onExpired={
				() => {
					secureLocalStorage.removeItem('grecaptcha')
					toggleElementById('wt-grecaptcha', true)
				}
			}
			size="normal"
		/>
	)
}