export interface IEnvioEmail {
  EmailDestinatario: string;
  Cpf_Cnpj: string;
  InstituicaoFinanceira: string;
  Telefone: string;
  MotivoContato: string;
  Mensagem: string;
  tipoContato: TipoContato;
  Aceite: boolean;
  Captcha: string;
}

export enum TipoContato {
  FaleConosco = 0,
  Ouvidoria = 1
}