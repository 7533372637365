import api from "../api"

export interface IValidarBoletoIn {
  cpf: string,
  linhaDigitavel: string,
  captcha: string
}
export interface IValidarBoletoOut {
  isValid: boolean,
  reason: string
}
export const validarBoleto = async (_validarBoleto: IValidarBoletoIn) => {
  const result = await api.post<IValidarBoletoOut>("/Boleto/validar", _validarBoleto)
  return result.data
}